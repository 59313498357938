table.striped {
  td, th {
    padding: 4px 6px;
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--table-row-border-color);

      &:hover {
        background: var(--table-row-hover-background);
      }
    }
  }

  thead {
    tr {
      border-bottom: 2px solid var(--table-header-border-color);
    }

    th {
      text-align: left;
    }
  }

  tr:nth-child(even) {
    background: var(--table-even-row-background);
  }
}

/*
 * A table where one column expands to fill the screen, while the
 * other columns shrink to fit their contents.
 */
table.autofit {
  td, th, .col-fit {
    white-space: nowrap;
    padding-right: 2em;
  }

  .col-expand {
    white-space: normal;
    width: 100%;
  }

  .col-normal {
    white-space: normal;
  }
}

table.search, table.aligned-vertical {
  tr {
    height: 2em;
  }

  th {
    text-align: right;
    padding-right: 1em;
    vertical-align: top;
  }

  td {
    vertical-align: top;
  }
}

table.aligned-vertical {
  height: 1.75em;
}

table.table-sm {
  th, td { padding: 0.25rem; }
}

table.table-md {
  th, td { padding: 0.5rem; }
}
