*, ::before, ::after {
  box-sizing: border-box;
}

/*
 * Make sure elements with the HTML5 [hidden] attribute (e.g. <span hidden>blah</span>)
 * really are hidden. Otherwise the `display` property can override the [hidden] attribute.
 */
[hidden] {
  display: none !important;
}

body {
  color: var(--text-color);
  background-color: var(--body-background-color);
  font-family: var(--body-font);
  font-size: 87.5%;
  line-height: 1.25em;
}

abbr[title=required] {
  display: none;
}

dd {
  margin-bottom: 1em;
}

dt {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6, .heading {
  font-family: var(--header-font);
  font-weight: bold;
  line-height: 1.5em;
  color: var(--header-color);
}

h1 {
  font-size: var(--text-xxl);
}

h2 {
  font-size: var(--text-lg);
}

h3, h4, h5, h6 {
  font-size: var(--text-md);
}

fieldset {
  margin-bottom: 1em;
}

header {
  margin: 0 0 1em 0;
  padding: 0;
}

img {
  border: none;
  vertical-align: middle;
}

input, select, textarea {
  background: var(--form-input-background);
  border: 1px solid var(--form-input-border-color);
  color: var(--form-input-text-color);
  padding-left: 0.25em;
  font: var(--body-font);
}

textarea {
  font-size: var(--text-sm);
}

/* placeholder text in <input type="text"> elements */
::placeholder {
  color: var(--form-input-placeholder-text-color);
  font-size: 0.9em;
  padding-left: 0.5em;
}

/* Highlight link anchor targets (e.g. /forum_topics/123#forum_post_456). Use !important to override even table row backgrounds. */
:target {
  background: var(--target-background) !important;
}

menu {
  margin: 0;
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
  }

  > li {
    margin: 0;
    padding: 0 0.2em;
    display: inline;
  }
}

p {
  margin-bottom: 1em;
}

table tfoot {
  margin-top: 2em;
}

details {
  summary {
    cursor: pointer;
    user-select: none;
    outline: none;
  }
}

.fineprint {
  color: var(--muted-text-color);
  font-style: italic;
  font-size: 0.8em;
}

.text-small {
  font-size: 0.9em;
}

a.link-plain {
  color: unset;

  &:hover {
    text-decoration: underline;
  }
}

.fixed-width-container {
  max-width: 70em;
}

.column-container {
  display: flex;

  .column-expand { flex: 1; }
  .column-shrink { flex: 0; }
  @media (max-width: 660px) { display: block; }
}

ul.list-bulleted {
  list-style: inside disc;
}

ul.list-inline {
  &, li {
    display: inline;
  }
}

.fit-width {
  max-width: 100%;
  height: auto !important;
}

.fit-height {
  max-height: 90vh;
  height: auto !important;
}

.svg-icon {
  display: inline-block;
  vertical-align: middle;
  height: 1em;
}

.mobile-only {
  display: none;
  @media (max-width: 660px) { display: initial; }
}

.desktop-only {
  @media (max-width: 660px) { display: none; }
}
