div#c-modqueue {
  div.post {
    padding: 1em;
    overflow: hidden;

    aside.column {
      @media (min-width: 660px) {
        padding-right: 1em;
      }
    }

    section.column {
      span.info {
        margin-right: 1.5em;
      }

      .quick-mod {
        display: inline-block;
        margin-bottom: 1em;
      }

      .post-flags-row {
        margin-top: 1em;
      }
    }

    
      a[data-tag-name="hard_translated"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="self_upload"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="nude_filter"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="third-party_edit"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="screencap"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="duplicate"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="image_sample"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="md5_mismatch"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="resized"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="upscaled"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="downscaled"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="resolution_mismatch"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="source_larger"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
      a[data-tag-name="source_smaller"] {
        background-color: var(--modqueue-tag-warning-color);
      }
    
  }
}
