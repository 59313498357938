// Source: https://github.com/FrogBot/sassy_bootstrap/blob/master/lib/_reset.sass

// Reset.sass
// Props to Eric Meyer (meyerweb.com) for his CSS reset file. We're using an
// adapted version here	that cuts out some of the reset HTML elements we will
// never need here (i.e., dfn, samp, etc).

// ERIC MEYER RESET
// ----------------

html, body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  /*  font-weight: normal;*/
  /*  font-style: normal;*/
  font-size: 100%;
  line-height: 1.25em;
  font-family: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol, ul {
  list-style: none;
}

q::before, q::after, blockquote::before, blockquote::after {
  content: "";
}

// Normalize.css
// Pulling in select resets form the normalize.css project
// --------------------------------------------------

// Display in IE6-9 and FF3
// -------------------------
// Source: http://github.com/necolas/normalize.css
html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// Focus states
a:focus {
  outline: thin dotted;
}

// Hover & Active

a:hover,
a:active {
  outline: 0;
}

// Display in IE6-9 and FF3
// -------------------------
// Source: http://github.com/necolas/normalize.css

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

// Prevents sub and sup affecting line-height in all browsers
// -------------------------
// Source: http://github.com/necolas/normalize.css

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

// Img border in a's and image quality
// -------------------------
// Source: http://github.com/necolas/normalize.css
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

// Forms
// -------------------------
// Source: http://github.com/necolas/normalize.css

// Font size in all browsers, margin changes, misc consistency

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: middle;
}

button,
input {
  line-height: normal;
  // FF3/4 have !important on line-height in UA stylesheet
  *overflow: visible;
  // Inner spacing ie IE6/7
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  // Inner padding and border oddities in FF3/4
  border: 0;
  padding: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  // Cursors on all buttons applied consistently
  -webkit-appearance: button;
  // Style clickable inputs in iOS
}

input[type="search"] {
  // Appearance in Safari/Chrome
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  // Inner-padding issues in Chrome OSX, Safari 5
}

textarea {
  overflow: auto;
  // Remove vertical scrollbar in IE6-9
  vertical-align: top;
  // Readability and alignment cross-browser
}
