div#related-tags-container {
  &.visible a#show-related-tags-link,
  &.hidden a#hide-related-tags-link,
  &.hidden div.related-tags {
    display: none;
  }
}

div.related-tags {
  background: var(--related-tags-container-background);
  overflow: auto;
  flex-wrap: wrap;

  div.tag-column {
    width: 15em;
  }

  li.selected a {
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-text-stroke */
    /* https://caniuse.com/text-stroke */
    -webkit-text-stroke: 0.5px;
    text-stroke: 0.5px;
  }

  /* On computers with a mouse, hide the related tag checkbox unless it's checked or hovered. */
  @media (hover: hover) {
    input[type="checkbox"] {
      visibility: hidden;
    }

    li.selected, li:hover {
      input[type="checkbox"] { visibility: visible; }
    }
  }
}
