@media screen and (max-width: 660px) {
  div#page {
    margin: 0 0.5rem;
    padding: 0;
  }

  header#top {
    position: relative;
    text-align: center;

    #maintoggle {
      display: block;
      font-weight: bold;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      font-size: 2em;
    }

    nav#nav {
      line-height: 2em;
      display: none;

      menu#main-menu, menu#subnav-menu {
        padding: 0.5rem;
      }

      menu#main-menu {
        background-color: var(--responsive-menu-background-color);
      }

      form.quick-search-form {
        display: block;
        padding: 0.5em 0;

        input {
          width: 100%;
          padding: 0;
        }
      }
    }
  }

  #posts #posts-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
}
