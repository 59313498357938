@import "../base/020_base.scss";

form.simple_form {
  margin: 0 0 1em 0;

  fieldset.inline-fieldset {
    > label {
      display: block;
      font-weight: bold;
    }

    div.input {
      display: inline;
      margin-right: 2em;
    }
  }

  div.input.boolean {
    label {
      display: inline;
      font-weight: normal;
      vertical-align: middle;
    }

    input {
      margin-right: 0.25em;
    }
  }

  div.input {
    margin-bottom: 1em;

    input[type=text], input[type=file], input[type=password], input[type=email], input[type=url] {
      width: 100%;
      max-width: 25em;
    }

    .hint {
      @extend .fineprint;
      padding-left: 1em;

      @media (max-width: 660px) {
        display: none;
      }
    }

    &.field_with_errors {
      input, select, textarea {
        border: 1px solid var(--form-input-validation-error-border-color);
      }

      span.error {
        display: block;
        color: var(--form-input-validation-error-text-color);
        font-style: italic;
        font-size: 0.8em;
      }
    }

    &.text, &.dtext {
      .hint {
        padding-left: 0;
        display: block;
      }

      textarea {
        width: 100%;
        max-width: 60rem;
        max-height: 15rem;

        &.text  { height: 20vh; }
        &.dtext { height: 40vh; }
      }
    }

    select[multiple] {
      height: 20vh;
      max-height: 15rem;
    }

    label {
      display: block;
      font-weight: bold;
      line-height: 1.5em;
    }

    &.radio_buttons {
      span.radio label {
        font-weight: normal;
        display: inline;
        margin: 0 2em 0 0.25em;
        vertical-align: middle;
      }
    }
  }

  &.stacked-hints {
    span.hint {
      display: block;
      padding-left: 0;
    }
  }
}

form.inline-form {
  display: table;
  width: 100%;

  > div.input {
    display: table-row;
    line-height: 2em;

    label {
      text-align: right;
      padding-right: 1em;
      white-space: nowrap;
      width: 1%;
    }

    label, input {
      display: table-cell;
      line-height: 1em;
    }
  }
}

form.quick-search-form {
  margin: 0;

  input {
    background: var(--quick-search-form-background);
  }
}

form.one-line-form {
  > input, > div.input {
    display: inline;

    label {
      display: inline;
      margin-right: 1em;
    }
  }
}

div.ui-dialog {
  textarea, input[type="text"] {
    width: 100%;
    max-width: 100%;
  }

  form.simple_form {
    margin-bottom: 0;

    div.input {
      margin-bottom: 0.5em;
    }

    div.input.hidden {
      display: none;
    }
  }

  /* the submit and close buttons */
  .ui-dialog-buttonpane {
    margin-top: 0;
    padding: 1em 1em 1em 0;

    .ui-button {
      margin: 0 0.25em;
    }
  }
}
