:root {
  --text-xs: 0.8em;
  --text-sm: 0.9em;
  --text-md: 1em;
  --text-lg: 1.16667em;
  --text-xl: 1.5em;
  --text-xxl: 2em;
  --header-font: Tahoma, Verdana, Helvetica, sans-serif;
  --body-font: Verdana, Helvetica, sans-serif;
  --monospace-font: 1.2em monospace;
}

$h1_padding: 0.8em 0 0.25em 0;
$h2_padding: 0.8em 0 0.25em 0;
$h3_padding: 0.8em 0 0.25em 0;
$h4_padding: 0.8em 0 0.25em 0;

@mixin animated-icon {
  content: "►";
  position: absolute;
  width: 20px;
  height: 20px;
  color: var(--preview-icon-color);
  background: var(--preview-icon-background);
  margin: 2px;
  text-align: center;
}

@mixin sound-icon {
  content: "♪";
  position: absolute;
  width: 20px;
  height: 20px;
  color: var(--preview-icon-color);
  background: var(--preview-icon-background);
  margin: 2px;
  text-align: center;
}

// https://fontawesome.com/how-to-use/on-the-web/advanced/css-pseudo-elements
@mixin fa-solid-icon($content) {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: $content;
}

@mixin active-link {
  color: var(--link-color);

  &:hover, &:focus {
    background-color: var(--subnav-menu-background-color);
    outline: none;
  }
}

@mixin inactive-link {
  color: var(--muted-text-color);

  &:hover {
    color: var(--link-hover-color);
    background-color: var(--subnav-menu-background-color);
  }
}
