.ui-autocomplete.ui-widget {
  font-size: var(--text-sm);
  border: 1px solid var(--autocomplete-border-color);
  box-shadow: var(--shadow-lg);

  div.ui-menu-item-wrapper {
    padding: 0.25em 0.4em;
    border: none;

    &.ui-state-active {
      cursor: pointer;
      background-color: var(--autocomplete-selected-background-color);
    }

    span.autocomplete-arrow {
      color: var(--text-color);
    }

    span.post-count {
      margin-left: 1em;
    }
  }

  /* Display a red wavy underline beneath misspelled tags. */
  /* https://stackoverflow.com/a/28152272 */
  li[data-autocomplete-type="tag-autocorrect"] .autocomplete-antecedent {
    position: relative;
    display: inline-block;
    background: var(--autocomplete-tag-autocorrect-underline);
    background-repeat: repeat-x;
    background-position-y: 1.2em;
    line-height: 1.5em;
  }
}
