@import "../base/000_vars.scss";

div#c-comments {
  div#a-index {
    div.header {
      margin-bottom: 1em;

      span.info {
        margin-right: 1.5em;
      }

      strong, time {
        margin-right: 0.3em;
      }
    }

    div.post-preview {
      &[data-tags~=animated], &[data-file-ext=swf], &[data-file-ext=webm], &[data-file-ext=zip], &[data-file-ext=mp4] {
        div.preview {
          position: relative;

          &::before {
            @include animated-icon;
          }
        }
      }

      &[data-has-sound=true] {
        div.preview {
          position: relative;

          &::before {
            @include sound-icon;
          }
        }
      }
    }

    div.post {
      display: flex;
      margin-bottom: 4em;

      div.preview {
        width: 154px;
        height: 154px;
        margin-right: 30px;
        overflow: hidden;
        text-align: center;
      }

      div.comment-section {
        flex: 1;
      }
    }
  }
}

form.edit_comment div.input.boolean {
  display: inline-block;

  label {
    font-weight: normal;
    vertical-align: initial;
  }
}

@media screen and (max-width: 660px) {
  div#c-comments div#a-index, div#c-comments div#a-show {
    div.post {
      display: block;
      margin: 2em 0;

      &:first-of-type {
        margin: 1em 0;
      }

      div.preview {
        margin: 1em auto;
        width: auto;
        height: auto;
      }
    }
  }
}
