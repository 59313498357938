div#c-moderator-dashboards {
  #column-left {
    margin-right: 2em;
  }

  div.activity {
    margin-bottom: 1em;
  }

  table {
    width: 100%;
  }

  caption {
    font-size: 1.1em;
    font-weight: bold;
    text-align: left;
  }

  th {
    text-align: left;
  }
}
