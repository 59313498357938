@import "../base/000_vars.scss";

div.prose {
  line-height: 1.4em;
  word-break: break-word;

  h1, h2, h3 {
    line-height: 1em;
  }

  h1 {
    font-size: var(--text-xl);
    padding: $h1_padding;
  }

  h2 {
    font-size: var(--text-xl);
    padding: $h2_padding;
  }

  h3 {
    font-size: var(--text-xl);
    padding: $h3_padding;
  }

  h4 {
    font-size: var(--text-xl);
    padding: $h4_padding;
  }

  h5 {
    font-size: var(--text-lg);
    padding: $h4_padding;
  }

  h6 {
    font-size: var(--text-md);
    padding: $h4_padding;
  }

  table {
    th {
      white-space: nowrap;
    }
  }

  ul {
    margin-left: 1em;
    margin-bottom: 1em;

    ul {
      margin-bottom: 0;
    }
  }

  li {
    list-style-type: disc;
  }

  code, pre {
    font: var(--monospace-font);
    background: var(--dtext-code-background);
  }

  pre {
    margin: 0.5em 0;
    padding: 0.5em 1em;
    white-space: pre-wrap;
  }

  blockquote {
    margin-bottom: 1em;
    padding-left: 0.5em;
    opacity: 0.75;
    border-left: 3px solid var(--dtext-blockquote-border-color);

    blockquote {
      opacity: 1.0;
    }
  }

  .tn {
    font-size: 0.8em;
    color: var(--muted-text-color);
  }

  div.expandable {
    margin-bottom: 1em;
    border: var(--dtext-expand-border);
  }

  div.expandable-header {
    padding: 0.4em;

    span {
      margin-right: 0.5em;
      font-weight: bold;
    }
  }

  div.expandable-content {
    display: none;
    padding: 0.4em;

    > :last-child {
      margin-bottom: 0;
    }
  }

  a.dtext-named-external-link::after {
    // https://fontawesome.com/icons/external-link-alt?style=solid
    @include fa-solid-icon("\f35d");

    font-size: 0.6em;
    padding: 0 0.2em 0 0.3em;
    vertical-align: 1px;
  }

  a.dtext-wiki-does-not-exist, a.dtext-tag-does-not-exist, a.dtext-artist-does-not-exist {
    text-decoration: dotted underline;
  }

  .spoiler:not(:hover), .spoiler:not(:hover) * {
    color: transparent !important;
  }

  .spoiler:hover {
    color: var(--dtext-spoiler-hover-color);
  }

  .spoiler {
    background: var(--dtext-spoiler-background-color);
  }

  details {
    margin-bottom: 1em;

    summary {
      margin-bottom: 1em;
    }
  }
}

// avoid empty gaps beneath dtext blocks in table rows.
table td div.prose > *:last-child {
  margin-bottom: 0;
}

div.dtext-preview {
  display: none;

  ul {
    margin-left: 1em;
  }
}

div#dtext-help {
  td {
    > *, div.spoiler p {
      margin: 0;
    }

    h1, h6 {
      padding: 0;
    }
  }

  #dtext-help-footnote {
    margin: 1em 0 0 0;
  }
}
