@import "./000_vars.scss";

a:link {
  color: var(--link-color);
  text-decoration: none;
}

a:visited {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-hover-color);
  text-decoration: none;
}

a:active {
  color: var(--link-color);
  text-decoration: none;
}

a.active {
  font-weight: bold;
}

a.active-link {
  @include active-link;
}

a.inactive-link {
  @include inactive-link;
}

a[rel*="external"] {
  word-break: break-all;
}
