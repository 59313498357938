$tooltip-line-height: 16px;
$tooltip-body-height: $tooltip-line-height * 4; // 4 lines high.

@mixin thin-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--post-tooltip-scrollbar-background);
    border: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--post-tooltip-scrollbar-thumb-color);
  }

  &::-webkit-scrollbar-thumb:active {
    background: var(--post-tooltip-scrollbar-thumb-color);
  }

  &::-webkit-scrollbar-track {
    background: var(--post-tooltip-scrollbar-track-background);
    border: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-track:hover {
    background: var(--post-tooltip-scrollbar-track-background);
  }

  &::-webkit-scrollbar-track:active {
    background: var(--post-tooltip-scrollbar-track-background);
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.tippy-box[data-theme~="post-tooltip"] {
  min-width: 20em;
  max-width: 40em !important;
  font-size: 11px;
  line-height: $tooltip-line-height;

  .tippy-content {
    padding: 0;

    > * {
      padding: 4px 8px;
    }

    .post-tooltip-body {
      @include thin-scrollbar;
      max-height: $tooltip-body-height;
      overflow-y: auto;
      display: flex;

      &.has-preview {
        max-height: 154px;
        padding: 8px;

        article.post-preview {
          margin: 0 8px 0 0;
        }
      }

      li {
        margin-right: 0;
      }

      .post-tooltip-body-left { flex: 0; }
      .post-tooltip-body-right { flex: 1; }
    }

    div.post-tooltip-header {
      background-color: var(--post-tooltip-header-background-color);
      display: flex;
      white-space: nowrap;
      align-items: center;

      .post-tooltip-info {
        margin-right: 0.5em;
        color: var(--post-tooltip-info-color);
        font-size: 10px;
        flex: 0;
      }

      a.user {
        margin-right: 0.5em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
        max-width: 11em;
      }

      .post-tooltip-source {
        flex: 1;
        text-align: right;
      }
    }
  }
}
