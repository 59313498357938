#filedropzone {
  background: var(--uploads-dropzone-background);
  padding: 0;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  .dz-preview {
    display: flex;
    flex-direction: column;
    text-align: center;

    &.dz-image-preview img {
      margin: 1em 0;
      object-fit: contain;
    }

    .dz-details {
      margin-bottom: 1em;

      .dz-filename, .dz-size {
        display: inline;
      }
    }
  }

  &.dz-started .dropzone-hint {
    display: none;
  }

  &.error {
    background: var(--error-background-color);
  }

  &.success {
    background: var(--success-background-color);
  }
}

.dz-progress {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 5px;

  background-color: var(--uploads-dropzone-progress-bar-background-color);

  .dz-upload {
    background-color: var(--uploads-dropzone-progress-bar-foreground-color);
    display: block;
    height: 5px;
  }
}
